<template>
    <div>
        <DataTable
            v-if="users"
            :value="users"
            class="p-datatable-customers p-datatable-striped user-list"
            :scrollable='true'
            :paginator="true"
            :lazy="true"
            scroll-height='calc(100vh - 350px)'
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :selection.sync="selectedUsers"
            :filters="filters"
            stripedRows
            :key="tableKey"
            @row-dblclick='rowClicked'
            @page="onPage"
            @sort="onSort"
            resizable-columns
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Пользователи</h5>
                    </div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText
                                type="text"
                                v-model="filters.login"
                                placeholder="Поиск"
                                @keydown="selectedFilter"
                            />
                        </span>
                            <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                        </div>
                        <div v-if='canEdit' class="p-p-0 p-new-btn">
                            <Button @click="$router.push('/users/new')" class="p-button">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Пользователи не найдены.</template>
            <template #loading>Загрузка списка пользователей. Пожалуйста, подождите.</template>
            <Column
                header="Логин"
                field="login"
                :sortable="true"
                header-class='user-header-login table-header'
                body-class='user-body-login table-body'
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.attributes.login }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <InputText
                        type="text"
                        v-model="filters.login"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @keydown="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Имя"
                field="name.firstName"
                header-class='user-header-firstname table-header'
                body-class='user-body-firstname table-body'
                :sortable="true"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.attributes.firstName }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <InputText
                        type="text"
                        v-model="filters.firstName"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @keydown="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Фамилия"
                field="name.lastName"
                header-class='user-header-lastname table-header'
                body-class='user-body-lastname table-body'
                :sortable="true"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.attributes.lastName }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <InputText
                        type="text"
                        v-model="filters.lastName"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @keydown="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Е-mail"
                field="attributes.email"
                header-class='user-header-email table-header'
                body-class='user-body-email table-body'
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.attributes.email }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <InputText
                        type="text"
                        v-model="filters.email"
                        class="p-column-filter p-my-2"
                        placeholder="Поиск"
                        @keydown="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Роль"
                field="role"
                header-class='user-header-role table-header'
                body-class='user-body-role table-body'
                filterMatchMode="contains"
            >
                <template #body="slotProps">
                    <span>{{ slotProps.data.role }}</span>
                </template>
                <template #filter v-if="showFilters">
                    <MultiSelect26
                        v-show="showFilters"
                        v-model="filters.role"
                        :options="roles"
                        :showToggleAll="true"
                        data-key="id"
                        option-value="id"
                        placeholder="Поиск"
                        option-label="attributes.name"
                        display="chip-count"
                        class="multiselect-custom"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Организация"
                field="organization"
                header-class='user-header-organization table-header'
                body-class='user-body-organization table-body'
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.organization }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-model="filters.organization"
                        v-show="showFilters"
                        option-value="id"
                        placeholder="Поиск"
                        :options="organizationsList"
                        option-label="attributes.publicName"
                        data-key="id"
                        class="customDropDown p-column-filter"
                        filter
                        @filter="debouncedOrganizationFilter"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Статус"
                header-class='user-header-status table-header'
                body-class='user-body-status table-body'
                field="status"
                filter-match-mode='equals'
            >
                <template #body="slotProps">
                    <span :title="getStatus(slotProps).label" :style="`background: ${ getStatus(slotProps).bgColor }; padding: 4px; border-radius: 4px;`">
                        {{ getStatus(slotProps).label }}
                    </span>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-model="filters.status"
                        :options="userStatuses"
                        placeholder="Поиск"
                        data-key="label"
                        option-value="value"
                        option-label="label"
                        class="p-column-filter"
                        :showClear="true"
                        @click.stop
                        @change="selectedFilter"
                    >
                    </Dropdown>
                </template>
            </Column>
            <Column
                header=""
                header-class='user-header-actions p-text-center'
                header-style='width: 52px;'
                body-class='user-body-actions'
                body-style="width: 52px; text-align: center;"
            >
                <template v-if='accessibleItems(listMenuItemsComputed).length' #body="slotProps">
                    <div
                        class="p-panel-header-icon p-link"
                        @click.stop="toggleRowMenu($event, slotProps)"
                    >
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu
                        class="redLastListElement"
                        :ref="`listMenu${slotProps.data.id}`"
                        :model="accessibleItems(listMenuItemsComputed)"
                        :popup="true"
                        :baseZIndex='10'
                    />
                </template>
                <template #header>
                    <Button
                        icon="pi pi-filter"
                        :class="filterClasses"
                        class="p-button-rounded p-button-outlined filter-btn"
                        @click="showFilters = !showFilters"
                    />
                </template>
            </Column>
            <template #paginatorLeft>
                <Button @click="downloadXLSX" label="Скачать XLSX" class="p-button p-component " />
            </template>
        </DataTable>
    </div>
</template>

<script>
import {
    getUsers,
    deleteUser,
    downloadUsers,
    resetUserPassword
} from '@/api/user';
import { getAllRoles } from '@/api/role';
import { getOrganizations } from '@/api/organization';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { autorizationAnotherUser } from '@/api/auth';
import { USER_STATUSES } from '@/constants/users';
import { USER_PERMISSIONS_MAP, DEFAULT_PAGE_SIZE } from '@/constants/common';

const {
    userWriteAll,
    userImpersonate,
    userWriteOwn
} = USER_PERMISSIONS_MAP

export default {
    name: 'usersList',
    mixins: [requestToastHandler],
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    data() {
        this.userStatuses = USER_STATUSES
        this.statusColors = {}
        USER_STATUSES.forEach(({ label, bgColor }) => this.statusColors[label] = bgColor);
        this.pageSize = DEFAULT_PAGE_SIZE;

        return {
            filterTimeout: null,
            sortField: '',
            organizationsList: null,
            loadingOrgs: false,
            editUserId: null,
            selectedUsers: null,
            showFilters: false,
            filters: { 'status': 'active' },
            filter: {},
            users: [],
            roles: [],
            userIncluded: [],
            tableKey: 1,
            currentPage: 1,
            totalRecords: null,
            showDelete: false
        }
    },
    methods: {
        ...mapMutations('auth', ['clearAccessData', 'setAccessData']),
        ...mapActions('auth', ['getMe', 'getNotifications']),
        async authAnotherUser() {
            try {
                const result = await autorizationAnotherUser(this.editUserId);
                this.clearAccessData();
                this.setAccessData(result.attributes);
                await this.getMe();
                await this.getNotifications();
            } catch (error) {
                this.$requestError(error.message);
                return;
            }

            this.$emit('loadingChange', false);
        },

        rowClicked({ data }) {
            this.editUserId = data.id
            this.editUser()
        },

        onPage({ page, rows }) {
            this.currentPage = page + 1;
            this.pageSize = rows;
            this.debouncedFilter();
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;

            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.getUsers();
            }, 1000);
        },

        debouncedOrganizationFilter({ value }) {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.updateOrganizationList(value);
            }, 1000)
        },

        async updateOrganizationList(val) {
            if (this.loadingOrgs) {
                return;
            }
            if (val?.length > 0) {
                try {
                    this.loadingOrgs = true;
                    const { organizations } = await getOrganizations({
                        pageSize: DEFAULT_PAGE_SIZE,
                        filter: {
                            'name.shortName': { '$ilike': val }
                        },
                        sortParametr: 'name.fullName'
                    })
                    this.organizationsList = organizations;
                } catch (error) {
                    this.$requestError(error.message);
                } finally {
                    this.loadingOrgs = false
                }
                return;
            }

            const { organizations } = await getOrganizations({
                pageSize: DEFAULT_PAGE_SIZE,
                sortParametr: 'name.fullName'
            });
            this.organizationsList = organizations;
        },

        toggleRowMenu(event, slotProps) {
            const { data: { id, status } } = slotProps;
            console.log(slotProps);

            if (id !== this.editUserId) {
                this.$refs[`listMenu${this.editUserId}`] && this.$refs[`listMenu${this.editUserId}`].hide(event)
            }
            this.editUserId = id;
            this.showDelete = status === 'new';

            this.$refs[`listMenu${id}`].toggle(event)
        },
        editUser() {
            if (!this.canEdit) return
            this.$router.push(`/users/edit/${this.editUserId}`);
        },

        async resetPassword() {
            try {
                this.$emit('loadingChange', true);
                const result = await resetUserPassword(this.editUserId);

                if (result.status === 204) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Новый пароль отправлен на почту',
                        life: 2500,
                    });
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        userRole(user) {
            let roleTitle = 'нет роли';
            if (!('relationships' in user)) return roleTitle;
            let roleData = user.relationships.role.data;

            if (roleData != null) {
                let role = this.userIncluded.find((role) => role.id === roleData.id);
                roleTitle = role.attributes.name;
            }
            return roleTitle;
        },
        userOrganization(user) {
            let organizationTitle = 'Организация не привязана';
            let organizationData = user.relationships.organization.data;
            if (organizationData != null) {
                let organization = this.userIncluded.find((organization) => organization.id === organizationData.id);
                organizationTitle = organization.attributes.shortName;
            }
            return organizationTitle;
        },
        async getUsers() {
            try {
                this.$root.$emit('loadingChange', true);

                let filter = {};
                if (this.filters.login && this.filters.login.length > 0) {
                    filter['login'] = { $like: this.filters.login };
                }

                if (this.filters.firstName && this.filters.firstName.length > 0) {
                    filter['name.firstName'] = { $ilike: this.filters.firstName };
                }

                if (this.filters.lastName && this.filters.lastName.length > 0) {
                    filter['name.lastName'] = { $ilike: this.filters.lastName };
                }

                if (this.filters.organization && this.filters.organization.length > 0) {
                    filter['organization.id'] = { $eq: this.filters.organization };
                }

                if (this.filters.role && this.filters.role.length > 0) {
                    filter['role'] = { $in: this.filters.role };
                }

                if (this.filters.email && this.filters.email.length > 0) {
                    filter['email'] = { $like: this.filters.email };
                }

                if (this.filters.status && this.filters.status.length > 0) {
                    if (this.filters.status === 'banned') {
                        filter['timestamps.blockedAt'] = { $lte: new Date() };
                    } else if (this.filters.status === 'active') {
                        filter['timestamps.confirmedAt'] = { $lte: new Date() };
                        filter['timestamps.blockedAt'] = { $eq: null };
                    } else {
                        filter['timestamps.confirmedAt'] = { $eq: null };
                        filter['timestamps.blockedAt'] = { $eq: null };
                    }
                }

                const { data: users, included, meta } = await getUsers({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                    include: true,
                    filter,
                    sort: this.sortField
                });
                this.userIncluded = included;
                this.users = users.map((user) => {
                    let status
                    const { active, banned } = user.attributes
                    if (banned) status = 'banned'
                    else if (active) status = 'active'
                    else status = 'new';
                    user.status = status
                    user.role = this.userRole(user);
                    user.organization = this.userOrganization(user);
                    return user;
                });

                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$root.$emit('loadingChange', false);
            }
        },

        getStatus(slotProps) {
            const status = this.userStatuses.find(item => item.value === slotProps.data.status);
            return status || {};
        },

        async removeUser() {
            this.$emit('loadingChange', true);
            try {
                const result = await deleteUser(this.editUserId);
                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }
                this.users = this.users.filter((user) => user.id !== this.editUserId);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        activeChange(id) {
            console.log('Сменили активность у юзера', id);
        },

        downloadXLSX() {
            let filter = {};

            if (this.filters.login && this.filters.login.length > 0) {
                filter['login'] = { '$like': this.filters.login };
            }

            if (this.filters.firstName && this.filters.firstName.length > 0) {
                filter['name.firstName'] = { '$like': this.filters.firstName };
            }

            if (this.filters.lastName && this.filters.lastName.length > 0) {
                filter['name.lastName'] = { '$like': this.filters.lastName };
            }

            if (this.filters.organization && this.filters.organization.length > 0) {
                filter['organization.id'] = { $eq: this.filters.organization };
            }

            if (this.filters.role && this.filters.role.length > 0) {
                filter['role'] = { $in: this.filters.role };
            }

            if (this.filters.email && this.filters.email.length > 0) {
                filter['email'] = { $like: this.filters.email };
            }

            if (this.filters.status && this.filters.status.length > 0) {
                if (this.filters.status === 'banned') {
                    filter['timestamps.blockedAt'] = { $lte: new Date() };
                } else if (this.filters.status === 'active') {
                    filter['timestamps.confirmedAt'] = { $lte: new Date() };
                    filter['timestamps.blockedAt'] = { $eq: null };
                } else {
                    filter['timestamps.confirmedAt'] = { $eq: null };
                    filter['timestamps.blockedAt'] = { $eq: null };
                }
            }

            downloadUsers({ filter: filter });
        },

        isActiveStatus(data) {
            return data.data.attributes.active
        },
        showMenuItem(item) {
            return (!item.permissionAccess || !item.permissionAccess.length) || item.permissionAccess.some(p => this.userPermissionsObject[p])
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
            'userPermissionsObject',
            'accessibleItems'
        ]),
        haveNewUser() {
            return !!this.users.find((user) => user.new);
        },
        showNewUser() {
            return this.haveNewUser ? 'width: 6rem' : 'width: 0rem';
        },
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        userImpersonate() {
            return !!this.userPermissionsObject[this.$userPermissionsMap.userImpersonate]
        },
        canEdit() {
            return [ userWriteAll, userWriteOwn ].some(p => this.userPermissionsObject[p])
        },

        listMenuItemsComputed() {
            return [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editUser()
                    },
                    permissionAccess: [ userWriteAll, userWriteOwn ]
                },
                {
                    label: 'Авторизоваться',
                    icon: 'pi pi-user',
                    command: () => {
                        this.authAnotherUser()
                    },
                    class: 'user-impersonate',
                    permissionAccess: [userImpersonate]
                },
                {
                    label: 'Сбросить пароль',
                    icon: 'pi pi-user-edit',
                    command: () => {
                        this.resetPassword();
                    },
                    permissionAccess: [
                        userWriteAll,
                        userWriteOwn
                    ]
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: this.removeUser
                        })
                    },
                    class: 'remove-row',
                    visible: this.showDelete,
                    permissionAccess: [ userWriteAll, userWriteOwn ]
                }
            ];
        }
    },

    async mounted() {
        const { organizations } = await getOrganizations({
            pageSize: 20
        });
        this.organizationsList = organizations;

        const result = await getAllRoles();
        if (result.message) {
            this.$requestError(result.message);
            return;
        }
        this.roles = result.roles;

        await this.getUsers();
    },
};
</script>

<style lang="scss" scoped>


.p-datatable {
    &-customers {
        ::v-deep {
            .p-paginator-bottom {
                border-width: 1px 0 0 0!important;
            }
            //.p-text-truncate {
            //    line-height: 1rem!important;
            //}
            .p-menu {
                width: min-content;
                white-space: nowrap;
            }
            .p-menuitem-link {
                .empty {
                    width: 14px;
                    height: 14px;
                    margin-right: 7px;
                }
            }
            //.p-panel-header-icon {
            //    width: 24px;
            //    height: 24px;
            //    border-radius: 50%;
            //    &:hover {
            //       background: rgba(135, 148, 163, 0.25);
            //    }
            //}
            //.table-header, .table-body {
            //    font-size: 13px;
            //    padding: 14px 0 14px 20px!important;
            //    white-space: nowrap!important;
            //}
            .user-header {
                &-login {
                    width: 7.8125vw;
                }
                &-firstname {
                    width: 6.25vw;
                }
                &-lastname {
                    width: 7.8125vw;
                }
                &-email {
                    width: 6.25vw;
                }
                &-role {
                    width: 9.375vw;
                }
                &-organization {
                    width: 17.1875vw;
                }
                &-status {
                    width: 6.25vw;
                }
            }
            .user-body {
                &-login {
                    width: 7.8125vw;
                }
                &-firstname {
                    width: 6.25vw;
                }
                &-lastname {
                    width: 7.8125vw;
                }
                &-email {
                    width: 6.25vw;
                }
                &-role {
                    width: 9.375vw;
                }
                &-organization {
                    width: 17.1875vw;
                }
                &-status {
                    width: 6.25vw;
                    .status-value {
                        background: #FFBBB3;
                        height: 22px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: min-content;
                        padding: 0 4px;
                        border-radius: 4px;
                        line-height: 1rem;
                        &.active {
                            background: #B3FFBB;
                        }
                    }
                }
            }

            //.p-paginator-bottom {
            //    margin-top: 12px;
            //    border-width: 1px 0 0 0;
            //}

        }
    }
}
::v-deep .customDropDown {
    width: 225px;
    .p-dropdown .p-dropdown-panel {
        left: 0 !important;
    }
    .p-dropdown-filter-icon {
        top: 10px;
        margin-right: 12px;
    }
    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

::v-deep .multiselect-custom {
    min-width: 98%;
    max-width: 98%;

    .p-multiselect-label {
        color: #8C8C8C;
        font-size: 13px;
        grid-template-columns: none;
        max-height: 22px;
        overflow-y: hidden;
        padding: 0 4px;
    }

    .p-multiselect-token {
        align-items: center;
        padding: 0rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        height: 20px;
        .p-multiselect-token-label {
            margin-right: 0.25rem;
            white-space: normal;
        }
    }
    .p-multiselect-trigger {
        width: 1.3rem;
    }
    .p-multiselect-item {
        width: 340px;
        min-width: 100%;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        right: 1.2rem;
    }
}
</style>
